<template>
  <div class="socials">
    <VAuthMiniContainer
      v-for="(social, index) in socials"
      :key="index"
      class="socials__social"
      @on-click-container="redirectToSocialAuth(social.name)"
    >
      <component
        :is="social.logo"
        class="socials__img"
      />
    </VAuthMiniContainer>
  </div>
</template>

<script>
import axios from 'axios';
import { captureException } from '@sentry/browser';
import VAuthMiniContainer from '@/components/ui/Auth/VAuthMiniContainer';
import IconOriginalFacebook from '@/assets/icons/socials/originalFacebook.svg';
import IconOriginalVk from '@/assets/icons/socials/originalVk.svg';
import IconOriginalGoogle from '@/assets/icons/socials/originalGoogle.svg';

export default {
  name: 'AuthSocials',
  components: {
    VAuthMiniContainer,
    IconOriginalFacebook,
    IconOriginalVk,
    IconOriginalGoogle,
  },

  data: () => ({
    socials: [
      {
        name: 'google',
        logo: 'IconOriginalGoogle',
      },
      {
        name: 'facebook',
        logo: 'IconOriginalFacebook',
      },
      {
        name: 'vkontakte',
        logo: 'IconOriginalVk',
      },
    ],
  }),

  methods: {
    async redirectToSocialAuth(social) {
      try {
        const response = await axios.get('/user/social/auth', {
          params: {'provider': social },
        });

        if (response.data.success) {
          window.location.href = response.data.redirectUrl;
        }
      } catch(e) {
        captureException(e);
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.socials {
  display: grid;
  grid-template-columns: repeat(3, 100px);
  justify-content: center;
  grid-column-gap: 10px;
  margin-bottom: 20px;
}

.socials__social {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 44px;
  cursor: pointer;

  &:hover {
    opacity: .8;
  }
}

.socials__img {
  height: auto;
  width: 24px;
}
</style>
